// Copyright 2019 The Chromium Authors. All rights reserved.
// Use of this source code is governed by a BSD-style license that can be
// found in the LICENSE file.

import {LitElement, html, css} from 'lit-element';

import {connectStore} from 'reducers/base.js';
import * as projectV0 from 'reducers/projectV0.js';
import * as sitewide from 'reducers/sitewide.js';

const OLD_VULNZ_URL = "https://vulnz.avm99963.com/";

/**
 * `<mr-vulnz-banner>`
 *
 * A banner for the Vulnz project to state where old vulnerability
 * reports can be found.
 *
 */
export class MrVulnzBanner extends connectStore(LitElement) {
  /** @override */
  static get styles() {
    return css`
      :host([hidden]) {
        display: none;
      }
      :host {
        display: block;
        font-weight: bold;
        color: var(--chops-black);
        background: var(--chops-primary-accent-bg);
        padding: 5px;
        text-align: center;
      }
    `;
  }

  /** @override */
  render() {
    return html`Much older vulnerability reports can be found at
      <a href="${OLD_VULNZ_URL}">${OLD_VULNZ_URL}</a>.`;
  }

  /** @override */
  static get properties() {
    return {
      hidden: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  /** @override */
  constructor() {
    super();

    this.hidden = true;
  }

  /** @override */
  stateChanged(state) {
    this.hidden = projectV0.viewedProjectName(state) !== 'vulnz';
  }
}

customElements.define('mr-vulnz-banner', MrVulnzBanner);
